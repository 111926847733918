import { Auth0Client } from '@auth0/auth0-spa-js';
import * as Sentry from '@sentry/react';
import config from 'config';
import { configure } from 'mobx';

if (process.env.NODE_ENV === 'development') {
	configure({
		// enforceActions: 'always',
		// computedRequiresReaction: true,
		// reactionRequiresObservable: true,
		// observableRequiresReaction: true,
		// disableErrorBoundaries: true,
	});
}

if (config.sentryEnabled === true || config.sentryEnabled === 'true') {
	Sentry.init({
		dsn: config.sentryUrl,
	});
}

if (import.meta.hot) {
	import.meta.hot.on('vite:beforeUpdate', () => {
		globalThis._auth0 = auth0;
	});
}

/**
 * Will be populated with an Auth0Client on page load, if one has not already been created.
 * Used to persist auth0 between hot reloads.
 */
let auth0: Auth0Client = globalThis._auth0;

export { auth0 };

/**
 * Triggers a logout through Auth0.
 * @returns A promise which resolves with undefined once logged out.
 */
export async function logout() {
	return await auth0.logout({
		returnTo: window.location.origin,
	});
}

/**
 * Fetches user through Auth0.
 * @returns A promise which resolves with the Auth0 user.
 */
export async function getUser() {
	const user = await auth0.getUser();

	if (user == null) {
		return;
	}

	/**
	 * Apple ID contains ".", which is not allowed in SpiceDB. Auth Service replaces it with
	 * "__". We do the same here so that we can continue to match IDs.
	 */
	if (user.sub != null && user.sub.startsWith('apple|')) {
		user.sub = user.sub.replaceAll('.', '__');
	}

	return user;
}

/**
 * List of Auth0 errors that we can recover from by asking to user to sign in again.
 */
const RECOVERABLE_ERRORS = [
	'login_required',
	'consent_required',
	'mfa_required',
];

/**
 * Retrieve the current tokens from Auth0 and triggers a refresh if needed.
 * @returns A promise that resolves to the result of Auth0Client#getTokenSilently({ detailedResponse: true }).
 */
export async function refreshToken() {
	try {
		return await auth0.getTokenSilently({ detailedResponse: true });
	} catch (err) {
		const errorName = (err as { error?: string })?.error;
		// if the error encountered is recoverable, try explicitly signing in
		if (errorName && RECOVERABLE_ERRORS.includes(errorName)) {
			await doLoginWithPopup();
			return await refreshToken();
		}

		throw err;
	}
}

/**
 * Retrieves the bearer token for the current user to be used for API calls.
 * Note: This will also refresh the tokens if needed.
 * @returns A promise that resolves to a string representing a bearer token.
 */
export async function getBearerToken() {
	if (process.env.NODE_ENV === 'test') {
		return '';
	}

	const { access_token } = await refreshToken();

	if (access_token && access_token.length) {
		return `Bearer ${access_token}`;
	}

	throw 'Failed to parse access_token for bearer from Auth0 response!';
}

function getAuth0Connection() {
	let auth0Connection: string | undefined = undefined;

	//grab sudomain to check if we need to do sso
	const subdomain = window.location.origin.match(
		/(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*)/i,
	);
	if (subdomain && subdomain.length == 2) {
		//should be exactly 2 elements in the regex matched results
		//check if we are doing sso
		if (subdomain[1].indexOf('sso-') != -1) {
			auth0Connection = subdomain[1].substring(
				subdomain[1].indexOf('sso-') + 4,
			);
		}
	}

	return auth0Connection;
}

async function doLoginWithPopup() {
	// dynamically import the token refresh to prevent a large entry bundle size and allow access to i18n
	const { triggerPopup } = await import('./tokenRefresh');
	return triggerPopup(() =>
		auth0.loginWithPopup().catch((err) => {
			console.warn(err);
		}),
	);
}

async function doLoginWithRedirect() {
	const returnTo = window.location.href.replace(window.location.origin, '');

	// Params that should be forwarded to Auth0 if present
	const forwardParams = (
		(config.auth.auth0ForwardParams as string | undefined) ?? ''
	).split('&');
	const urlParams = new URLSearchParams(window.location.search);
	const forwards: Record<string, string> = {};
	for (const param of forwardParams) {
		const val = urlParams.get(param);
		if (val != null) {
			forwards[param] = val;
		}
	}

	await auth0.loginWithRedirect({
		redirect_uri: window.location.origin + `${import.meta.env.BASE_URL}`,
		appState: { returnTo },
		...forwards,
	});
}

async function continueToApp(getToken = true) {
	if (getToken) {
		// pre-populate the auth0 token cache
		await refreshToken();
	}

	const app = await import('./main');
	return app.default();
}

export async function initialize() {
	try {
		// create a new auth0 client with the given config
		auth0 = new Auth0Client({
			domain: config.auth.auth0Domain ?? '',
			cookieDomain: config.auth.auth0CookieDomain ?? undefined,
			client_id: config.auth.auth0ClientId ?? '',
			audience: config.auth.auth0Aud ?? '',
			connection: getAuth0Connection(),
			useRefreshTokens: true,
			cacheLocation: 'localstorage',
		});

		try {
			await auth0.getTokenSilently();
		} catch (err) {
			const errorName = (err as { error?: string })?.error;
			// if the error encountered is recoverable, do nothing and try to sign in below
			// Otherwise, throw an error so we go to the error page
			if (errorName == null || !RECOVERABLE_ERRORS.includes(errorName)) {
				throw err;
			}
		}

		// check if a auth callback has occurred
		const { search } = window.location;
		if (
			search.includes('code=') ||
			search.includes('state=') ||
			search.includes('error=') ||
			search.includes('error_description=')
		) {
			let returnTo = '/';
			const { appState = {} } = await auth0.handleRedirectCallback();
			// if a specific returnTo path is found in appState, use it instead of '/'
			returnTo = appState.returnTo ?? returnTo;
			window.history.replaceState({}, document.title ?? '', returnTo);
			return await continueToApp();
		} else if (await auth0.isAuthenticated()) {
			// no callback occurred, user is already logged in
			return await continueToApp();
		} else {
			// no login present, no callback occurred, user must login
			await doLoginWithRedirect();
		}
	} catch (err) {
		// Log any errors to the console so we debug, but try to open the app anyways
		// The app itself should try to handle error states gracefully
		console.error('Bootstrap', err);
		return await continueToApp(false);
	}
}

window.addEventListener('load', initialize);
